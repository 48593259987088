import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from 'docz';
import { UseEventListenerExample } from './use-event-listener.example.tsx';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "useeventlistener"
    }}>{`useEventListener`}</h1>
    <p>{`Will setup a listener on the window object and run handler every time that
action is triggered.`}</p>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { useState } from 'react';
import { useEventListener } from '@fransvilhelm/hooks';

const UseEventListenerExample = () => {
  const [keys, setKeys] = useState<string[]>([]);
  useEventListener('keypress', ev => {
    setKeys([...keys, ev.key]);
  });

  return <output>{keys.join(' > ')}</output>;
};
`}</code></pre>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <Playground __position={0} __code={'<UseEventListenerExample />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      UseEventListenerExample,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UseEventListenerExample mdxType="UseEventListenerExample" />
    </Playground>
    <h2 {...{
      "id": "parameters"
    }}>{`Parameters`}</h2>
    <p><inlineCode parentName="p">{`useEventListener`}</inlineCode>{` accepts three arguments.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Param`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`type`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`true`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The event type to listen for (e.g. `}<inlineCode parentName="td">{`scroll`}</inlineCode>{`, `}<inlineCode parentName="td">{`keypress`}</inlineCode>{`)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`handler`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`function`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`true`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The event handler, recieving an untouched version of the emitted event`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`options`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`boolean `}{`|`}{` `}<a parentName="td" {...{
              "href": "https://github.com/WICG/EventListenerOptions"
            }}>{`AddEventListenerOptions`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`undefined`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Options to provide as last arguments to window.addEventListener`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "returns"
    }}>{`Returns`}</h2>
    <p><inlineCode parentName="p">{`useEventListener`}</inlineCode>{` returns nothing.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      